import { ref, watch } from 'vue';

export const useCode = () => {
  const code = ref('');
  const isError = ref(false);
  const isSuccess = ref(false);
  const isValid = ref(false);
  const errorMessage = ref('Invalid code');
  const codeLength = 6;

  watch(code, (newValue) => {
    isValid.value = newValue.length === codeLength;
    isError.value = newValue.length > codeLength;
  });
  watch(isSuccess, () => {
    if (isSuccess.value) {
      isError.value = false;
    }
  });

  return {
    code,
    errorMessage,
    isError,
    isSuccess,
    isValid,
    codeLength,
  };
};

export default useCode;
