<template>
  <ResponsiveBlockWrapper>
    <AuthCardWrapper title="TOTP Registration" title-size="24px">
      <AppText
        size="16px"
        :line-height="1.3"
        variant="div"
        mt="10px"
        mb="30px"
        color="var(--color-black-06)"
        style="width: 340px; text-align: center;"
      >
        Scan this QR code with any software authenticator application.
      </AppText>

      <div class="block-qr flex-center">
        <Transition name="show-fast">
          <QRcode
            v-if="link"
            :key="link"
            :data="link"
            is-switch
          />
        </Transition>
      </div>
      <div class="input-block d-flex flex-column align-items-center">
        <AppText
          size="14px"
          :color="`rgb(var(--color-${localizedErrors?.code?.message ? 'error' : 'black'}))`"
        >
          {{ localizedErrors.code?.message ? localizedErrors.code?.message : 'Enter code from the app' }}
        </AppText>
      </div>
      <ActionForm
        :request-fields="requestFields"
        :validation-schema="validationSchema"
        :response-errors="fieldsErrors"
        @validate="onPassedValidation"
        @error="onError"
      >
        <template #default="{ errors, action }">
          <SplitInput
            v-model="requestFields.code"
            type="dashed"
            :state="errors?.code ? 'error' : ''"
            is-auto-clear-error
            is-centered
            hide-error-message
            @submit="onInput(action)"
          />
        </template>
      </ActionForm>
    </AuthCardWrapper>
  </ResponsiveBlockWrapper>
</template>

<script>
import { onBeforeMount, ref } from 'vue';

import QRcode from '@/components/QRcode.vue';
import ResponsiveBlockWrapper from '@/components/Animation/ResponsiveBlockWrapper.vue';
import SplitInput from '@/components/Inputs/SplitInput.vue';

import { useCode } from '@/composables/useCode';
import { ActionForm, codeRule, fieldValidation } from '@/validation';

import AuthCardWrapper from '../../AuthCardWrapper.vue';
import { adminCompleteRegistration, totpEnable, totpGenerate } from '../api';

export default {
  name: 'RegStep2',
  components: {
    SplitInput,
    ActionForm,
    AuthCardWrapper,
    QRcode,
    ResponsiveBlockWrapper,
  },
  emits: ['submit', 'error'],
  setup(props, { emit }) {
    const link = ref(null);

    const { code, isError } = useCode();
    const {
      validationSchema,
      requestFields,
      fieldsErrors,
      localizedErrors,
      processError,
    } = fieldValidation({ fieldName: 'code', rule: codeRule });

    const onPassedValidation = async () => {
      const { isSuccess, errorObject } = await totpEnable(requestFields.code);

      if (isSuccess) {
        await adminCompleteRegistration();
        emit('submit');
      } else {
        processError(errorObject, 'wrongToken', 'code');
      }
    };

    const onInput = (method) => {
      if (requestFields.code.length === 6) {
        method();
      }
    };

    onBeforeMount(async () => {
      const { isSuccess, otpLink } = await totpGenerate();

      if (isSuccess) {
        link.value = otpLink;
      } else {
        emit('error');
      }
    });

    const onError = (errors) => {
      fieldsErrors.value = errors;
      Object.assign(fieldsErrors.value, errors);
    };

    return {
      validationSchema,
      requestFields,
      fieldsErrors,
      onPassedValidation,

      onInput,

      code,
      isError,

      link,
      onError,
      localizedErrors,
    };
  },

};
</script>

<style lang="scss" scoped>
.block-qr {
  height: 190px;
}
:deep(.form__wrapper) {
  padding: 60px 0 30px;
}
.input-block{
  margin: 30px 0 18px;
}
</style>
